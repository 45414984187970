import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withSegmentPageViewTracking } from 'components/core/library/segment';
import { Image } from 'components/core/Image';
import { H3, Paragraph } from 'components/core/Typography';
import { Link } from 'components/core/Link';
import { Text } from 'components/core/Text';
import fourOhfour from 'assets/images/error/fourOhfour.svg';
import faceFourOhFour from 'assets/images/error/faceFourOhFour.svg';
import { SearchBox } from 'components/search/SearchBox';
import withSearchHandler from 'decorators/withSearchHandler';
import truckIcon from 'assets/images/error/truckIcon.svg';
import supportIcon from 'assets/images/error/supportIcon.svg';
import unitSizeHelpIcon from 'assets/images/error/unitSizeHelpIcon.svg';
import './newErrorPage.scss';
import { Page } from 'components/core/Page';
import { Helmet } from 'react-helmet';

export const enhance = compose(
	withSegmentPageViewTracking('errorPage'),
	withSearchHandler
);

const typesOfHelps = [
	{
		title: 'Need RV Storage?',
		// eslint-disable-next-line max-len
		description:
			'Explore our RV storage tips and find the perfect RV storage unit.',
		url: '/rv-storage.html',
		segmentLabel: 'Read About RV Storage',
		image: truckIcon,
	},
	{
		title: 'Need Storage Size Help?',
		// eslint-disable-next-line max-len
		description:
			'Review SpareFoot’s storage size guide to learn which unit size is right for you.',
		url: '/storage-unit-size-guide.html',
		segmentLabel: 'View Size Guide',
		image: unitSizeHelpIcon,
	},
	{
		title: 'Want a Hand?',
		// eslint-disable-next-line max-len
		description:
			"Don't hesitate to get in touch! Let us know how we can assist you.",
		url: 'https://about.sparefoot.com/contact-us',
		segmentLabel: 'Contact SpareFoot',
		image: supportIcon,
	},
];

export function ProductCard({ image, title, description, url, segmentLabel }) {
	return (
		<>
			<Image
				src={image}
				alt=""
				lazyLoad={true}
			/>
			<div className="card-content">
				<div>
					<H3
						size="medium"
						weight="ultraBold"
						className="card-title"
					>
						{title}
					</H3>
					<Paragraph
						size="smaller"
						weight="standard"
						className="card-description"
					>
						{description}
					</Paragraph>
				</div>
				{url && (
					<Link
						// eslint-disable-next-line max-len
						href={`${url}`}
						segmentLabel="storage unit size guide"
					>
						<Text size="small">{segmentLabel}</Text>
					</Link>
				)}
			</div>
		</>
	);
}

ProductCard.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	url: PropTypes.string,
	segmentLabel: PropTypes.string,
};

export function NewFourOhFourPage({
	statusCode,
	staticContext,
	handleSearch,
	moveInDate,
	reservationWindow,
}) {
	// Pushes the app status code up into the server response
	if (staticContext) {
		/* eslint-disable no-param-reassign */
		staticContext.statusCode = statusCode;
		/* eslint-enable */
	}
	return (
		<div className="new-error-page">
			<Helmet>
				<meta
					name="robots"
					content="noindex"
				/>
			</Helmet>
			<div className="section-content">
				<ul className="types-list-search">
					<li className="type-item">
						<div className="product-card">
							<div className="card-content">
								<ProductCard
									{...{
										title: 'This page has been packed up and stored out of reach',
										description:
											"Have no fear, we'll get you on track!",
										image: faceFourOhFour,
									}}
								/>

								<SearchBox
									handleSearch={handleSearch}
									moveInDate={moveInDate}
									reservationWindow={reservationWindow}
									segmentPrefix="search box"
									buttonText="Find storage"
								/>
							</div>
						</div>
					</li>
					<li className="type-item">
						<Image
							src={fourOhfour}
							alt="test"
							width="319"
							height="451"
						/>
					</li>
				</ul>
				<ul className="types-list">
					{typesOfHelps.map((item) => (
						<li
							className="type-item"
							key={`${item.title}`}
						>
							<div className="product-card product-card-search">
								<ProductCard {...item} />
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

NewFourOhFourPage.propTypes = {
	statusCode: PropTypes.number,
	staticContext: PropTypes.object,
	location: PropTypes.string,
	moveInDate: PropTypes.instanceOf(Date),
	reservationWindow: PropTypes.instanceOf(Date),
	handleSearch: PropTypes.func,
};

NewFourOhFourPage.defaultProps = {
	statusCode: 404,
};

export default enhance(NewFourOhFourPage);
